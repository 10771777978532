import http from '../../services/helpers/http';

const api = {
	changeActive(id) {
		return http.post('ObjectReview/ChangeActive', id);
	},

	getReviewTypeList() {
		return http.get('ReviewEvaluatorType/List');
	},
};

export default api;
