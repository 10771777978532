<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="headerTitle"
		tooltipMsg="manualforobject3314"
		:showActionDelete="!!detailEntityId"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	></detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';

import service from './object-review.service';
import { model, fields } from './object-review.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'ObjectReview',
			routeParam: 'OreviewId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		headerTitle() {
			const title = this.$t('detailofobjectr');
			const id = this.$t('id');
			const idNum = this.model.OreviewId;
			return `${title} ${id} ${idNum}`;
		},
	},

	methods: {
		async loadResources() {
			const [reviewTypes] = await Promise.all([service.getReviewType(this.$store)]);

			this.updateSchemaFields({
				OreviewLanguage: { values: this.$store.state.country.data },
				RevtypeId: { values: reviewTypes },
				OreviewIsActive: {
					onChanged(model) {
						service.changeActiveStatusDetail(model.OreviewId);
					},
				},
			});
		},

		transformDtoToModel(dtoModel) {
			dtoModel.DateRange = {
				startDate: dtoModel.OreviewResidenceFromDatetime,
				endDate: dtoModel.OreviewResidenceToDatetime,
			};

			dtoModel.ObjectLink = {
				Name: dtoModel.ObjectName + ' ' + this.$i18n.t('id') + ' ' + dtoModel.ObjectId,
				Href: dtoModel.ObjectUrl,
			};

			this.updateSchemaFields({
				OreviewChecked: {
					user: dtoModel.OreviewVerifiedByUserObject,
					date: dtoModel.OreviewDateVerified,
				},
				OreviewIsActive: {
					datechange: dtoModel.OreviewActivationDatetime
						? moment(dtoModel.OreviewActivationDatetime).format('DD.MM.YYYY HH:mm')
						: '',
				},
			});

			return dtoModel;
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.OreviewId, this.$modal, this.deleteModel);
		},
	},
};
</script>