import vueFormGenerator from 'vue-form-generator';

export const model = {
	ObjectId: 0,
	OreviewActivationCode: null,
	OreviewActivationDatetime: null,
	OreviewChecked: null,
	OreviewDateVerified: null,
	OreviewVerifiedByUserObject: null,
	OreviewAdminNote: null,
	OreviewCreatedDatetime: null,
	OreviewEmail: null,
	OreviewFirstname: null,
	OreviewLastname: null,
	OreviewId: 0,
	OreviewIsActive: 0,
	OreviewLanguage: null,
	OreviewNegatives: null,
	OreviewPositives: null,
	OreviewCityEvaluation: null,
	OreviewRegionEvaluation: null,
	OreviewReplyDatetime: null,
	OreviewReplyText: null,
	OreviewReservationId: 0,
	OreviewResidenceFromDatetime: null,
	OreviewResidenceToDatetime: null,
	RevtypeId: 0,
	Ratings: {},
	ObjectLink: {
		Name: null,
		Href: null,
	},
	ObjectName: null,
	DateRange: {
		startDate: null,
		endDate: null,
	},
};

export const fields = [
	{
		fields: [
			{
				type: 'single-datepicker',
				language: 'sk',
				model: 'OreviewCreatedDatetime',
				showtime: true,
				autoselect: true,
				required: true,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'created:',
				},
			},
			{
				type: 'objectlink',
				icon: 'eye',
				model: 'ObjectLink',
				required: true,
				i18n: {
					label: 'ratedobject:',
				},
			},
			{
				type: 'switchAction',
				model: 'OreviewIsActive',
				titleActivate: 'active2190',
				titleDeactivate: 'inactive2191',
				textActivate: 'yes2187',
				textDeactivate: 'no2188',
				datechange: null,
				i18n: {
					label: 'active:',
					textOn: 'yes2205',
					textOff: 'no2206',
				},
			},
			{
				type: 'click-actions',
				model: 'OreviewChecked',
				i18n: {
					label: 'checked:',
				},
			},
			{
				type: 'range-datepicker',
				language: 'sk',
				model: 'DateRange',
				i18n: {
					label: 'dateofstay:',
				},
			},
			{
				type: 'select',
				model: 'RevtypeId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {},
				i18n: {
					label: 'typeofevaluator',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OreviewFirstname',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'name:',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OreviewLastname',
				i18n: {
					label: 'surname:',
				},
			},
			{
				type: 'input',
				inputType: 'email',
				model: 'OreviewEmail',
				required: true,
				validator: vueFormGenerator.validators.email,
				i18n: {
					label: 'e-mail:',
				},
			},
			{
				type: 'textArea',
				rows: 6,
				model: 'OreviewPositives',
				min: 1,
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'prosoftheaccomm',
				},
			},
			{
				type: 'textArea',
				rows: 6,
				model: 'OreviewNegatives',
				min: 1,
				i18n: {
					label: 'consoftheaccomm',
				},
			},
			{
				type: 'starrating',
				inputType: 'text',
				model: 'Ratings',
				required: true,
				i18n: {
					label: 'evaluatedparame',
				},
			},
			{
				type: 'textArea',
				rows: 8,
				model: 'OreviewReplyText',
				min: 1,
				i18n: {
					label: "landlord'sansw",
				},
			},
			{
				type: 'single-datepicker',
				language: 'sk',
				model: 'OreviewReplyDatetime',
				showtime: false,
				autoselect: true,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'dateofanswer:',
				},
			},
			{
				type: 'textArea',
				rows: 6,
				model: 'OreviewCityEvaluation',
				i18n: {
					label: 'cityrating:',
				},
			},
			{
				type: 'textArea',
				rows: 6,
				model: 'OreviewRegionEvaluation',
				i18n: {
					label: 'regionrating:',
				},
			},
			{
				type: 'select',
				model: 'OreviewLanguage',
				required: true,
				validator: vueFormGenerator.validators.string,
				values: [],
				selectOptions: {
					value: 'LanguageCode',
					name: 'LanguageCode',
				},
				i18n: {
					label: 'languange:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'verified-switch',
				model: 'OreviewChecked',
				user: '',
				date: null,
				i18n: {
					label: 'checked:',
				},
			},
			{
				type: 'textArea',
				rows: 4,
				model: 'OreviewAdminNote',
				min: 1,
				i18n: {
					label: 'remark:',
				},
			},
		],
	},
];
