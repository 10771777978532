import utils from '../../services/helpers/utils';
import i18n from '../../services/lang';
import api from './object-review.api';

const service = {
	openChangeActiveModal(modal, changeActiveFnc, itemId, isActive) {
		modal.show({
			title: isActive ? i18n.t('inactive2191') : i18n.t('active2190'),
			text: isActive ? i18n.t('no2188') : i18n.t('yes2187'),
			modalClass: 'modal-danger',
			variant: 'danger',
			okTitle: i18n.t('ok2344'),
			cancelTitle: i18n.t('cancel2343'),
			onConfirm() {
				return changeActiveFnc(itemId);
			},
		});
	},

	changeActiveStatusDetail(itemId) {
		api.changeActive(itemId);
	},

	getReviewType(store) {
		return api.getReviewTypeList().then((resp) => {
			return utils.convertItems(resp.data, function(item) {
				let locale = store.state.lang.locale;
				let val = locale === 'cs-CZ' ? item.RevtypeNameCz : locale === 'sk-SK' ? item.RevtypeNameSk : item.RevtypeNameEn;
				return { id: item.RevtypeId, name: val };
			});
		});
	},

	fillModel(model, schema, respData) {
		model = respData;
		model.DateRange = new Object({
			startDate: respData.OreviewResidenceFromDatetime,
			endDate: respData.OreviewResidenceToDatetime,
		});
		model.ObjectLink = {
			Name: model.ObjectName + ' ' + i18n.t('id') + ' ' + model.ObjectId,
			Href: model.ObjectUrl,
		};
		schema.groups[0].fields[2].datechange = model.OreviewActivationDatetime
			? moment(model.OreviewActivationDatetime).format('DD.MM.YYYY HH:mm')
			: '';
	},
};

export default service;
